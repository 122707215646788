<template>
  <suspense>
  	<se-static-home-page />
  </suspense>
</template>

<script setup lang="ts">
import SeStaticHomePage from "~/modules/static/views/home.page.vue";
</script>


<script lang="ts">
import {theme} from "../../theme";

export default {
  meta() {
    return theme.home.seo
  },
}
</script>

